import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hello Mate, I'm Karthikeyan Dhanapal`}</h1>
    <p>{`Associate Senior Software Engineer `}<a parentName="p" {...{
        "href": "https://www.cerner.com/"
      }}>{`@cerner`}</a>{` 👨🏻‍💻  |  Quantum Physics Enthusiast 🧙  |  Known Wanderer in an Unknown Universe 👾`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      